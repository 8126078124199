

.card-event-container {
  display: flex;
  align-items: flex-start;
  padding: .5rem;
  border-radius: 0.5rem 0.5rem 0px 0px;
}

.card-event-container .card-event-details h4 {
  font-weight: 700;
  font-size: 1rem;
  color: var(--color-active);
  margin: 0.5rem 0rem;
}

.card-event-container .card-event-details .media-playlist {
  margin-bottom: .5rem;
}

.card-event-container .card-event-details .media-playlist,
.card-event-container .card-event-details .media-date {
  display: flex;
  align-items: center;
}

.card-event-container .card-event-details .media-playlist > span,
.card-event-container .card-event-details .media-date > span {
  margin-left: 0.5rem;
  font-weight: 600;
  font-size: .8rem;
  color: var(--action-color);
  font-family: 'Inter', sans-serif;
}

.card-event-footer {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-bg-white-blueish);
  padding: .5rem 1rem;
  border-radius: 0px 0px 0.5rem 0.5rem;
  background: #F9FAFD;
}
.card-event-footer-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.75rem;
 
}
.card-event-footer-column {
  display: flex;
  flex-direction: column;
 
}

.adsign-footer {
  background-color: #F9FAFD;
}

.adsign-card {
  background-color: #FFFFFF;
}

.card-event-footer .card-event-footer-right {
  display: flex;
}

.card-event-footer .card-event-footer-right > ul {
  display: flex;
  margin: 0px;
}

.card-event-footer .card-event-footer-right > ul > li button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.card-event-container img,
.card-event-container video {
  width: 120px;
  height: 120px;
  object-fit: contain;
  border: 1px solid var(--color-stroke);
  border-radius: .5rem;
}

.card-event-details {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-event-details ul.card-event-top {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
}

.card-event-details ul.card-event-top > li {
  margin-bottom: .25rem;
}

.card-event-details ul.card-event-top > li > i {
  margin-right: .5rem;
}

.card-event-details ul.card-event-bottom {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
}

.card-event-details ul.card-event-bottom li {
  cursor: pointer;
}

.card-event-details ul.card-event-bottom li:hover {
  color: var(--color-primary);
}

.card-event-details ul.card-event-bottom > li:not(:last-child) {
  margin-right: 1rem;
}