.casestudy-library {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.casestudy-library .casestudy-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  align-items: flex-start;
}
.casestudy-library .casestudy-header .page-title{
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem; /* 133.333% */
    color: black;
}

.casestudy-library .casestudy-header .casestudy-header-left {
  display: flex;
  align-items: center;
}

.casestudy-library .casestudy-header .casestudy-header-left .playlist-wrapper {
  display: flex;
  margin-left: 0.5rem;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  background-color: #fff;
  border-radius: 0.35rem;
  border: 1px solid var(--color-border);
  padding: 0.5rem 0.7rem;
  position: relative;
  cursor: pointer;
}

.casestudy-library .casestudy-header .casestudy-header-left .playlist-wrapper i.playlist-open {
  transform: rotate(180deg);
  transition: all 250ms ease-in-out;
}

.casestudy-library .casestudy-header .casestudy-header-left .playlist-wrapper i.playlist-closed {
  transform: rotate(0deg);
  transition: all 250ms ease-in-out;
}

.casestudy-library .casestudy-header .casestudy-header-left .playlist-wrapper .playlist-selection {
  display: flex;
  align-items: center;
}

.casestudy-library .casestudy-header .casestudy-header-left .playlist-wrapper .playlist-selection > span {
  margin-left: 0.25rem;
  font-weight: 600;
  color: var(--color-black);
}

.casestudy-library .casestudy-header .searchbar {
  display: flex;
  align-items: center;
  width: max-content;
  border-radius: 0.35rem;
  border: 1px solid var(--color-border);
  padding: 0.5rem 0.7rem;
  background-color: #fff;
  margin: 0px;
}

.casestudy-library .casestudy-header .searchbar-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.casestudy-library .casestudy-header .searchbar > i {
  font-size: 1.5em;
  color: var(--color-text-placeholder);
}

.casestudy-library .casestudy-header .searchbar > input.search-input {
  border: none;
  background-color: transparent;
  font-family: 'Inter', sans-serif;
  width: 200px;
  color: var(--color-black);
}

.casestudy-library .casestudy-header .searchbar > input.search-input::placeholder {
  color: var(--color-text-placeholder);
}

.casestudy-library .casestudy-header .searchbar > input.search-input:focus {
  outline: none;
}

.casestudy-library .casestudy-header .btn-primary {
  color: #fff;
  background-color: var(--primary-button);
  border-radius: 0.35rem;
  padding: 0.5rem 1rem;
  height: auto;
  border: none;
  font-weight: 600;
  box-shadow: 0px 12px 12px rgb(83 215 245 / 10%);
  cursor: pointer;
}

.casestudy-library .casestudy-header .btn-primary:hover {
  opacity: 0.8;
}
.casestudy-library .casestudy-header .btn-primary {
  color: #fff;
  background-color: var(--primary-button);
  border-radius: 0.35rem;
  padding: 0.5rem 1rem;
  height: auto;
  border: none;
  font-weight: 600;
  box-shadow: 0px 12px 12px rgb(83 215 245 / 10%);
  cursor: pointer;
}

.casestudy-library .casestudy-header .btn-primary:hover {
  opacity: 0.8;
}

.casestudy-library .casestudy-header .actions__wrapper {
  display: flex;
  column-gap: 12px;
}

.casestudy-library .casestudy-header .actions__wrapper .casestudy__delete {
  border-radius: 10px;
  background-color: #ff4d4f;
  color: #fff;
}

.casestudy-library .casestudy-header .actions__wrapper .casestudy__delete:hover {
  background-color: #ff7875;
}

.casestudy-library .casestudy-subheader {
  display: flex;
  justify-content: flex-end;
}

.casestudy-library .casestudy-subheader .casestudy-button-group > button.active {
  background-color: var(--color-active);
}

.casestudy-library .casestudy-subheader .casestudy-button-group > button {
  border: none;
  background-color: var(--color-secondary);
  color: #fff;
  padding: 0.25rem 0.8rem;
  cursor: pointer;
}

.casestudy-library .casestudy-subheader .casestudy-button-group > button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.casestudy-library .casestudy-subheader .casestudy-button-group > button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.casestudy-library .casestudy-content {
  display: flex;
  justify-content: flex-start;
  column-gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
}

.casestudy-library .title {
  color: var(--color-primary);
  font-size: 1.4rem;
  text-transform: capitalize;
}

.casestudy-library .casestudy-content .casestudy-card {
  /* width: 25%; */
  width: 100%;
  /* width: 100%; */
  position: relative;
  padding: 1rem 0.5rem;
  margin-right: 0 !important;
}

.casestudy-library .casestudy-content .casestudy-card:hover .casestudy-overlay {
  opacity: 1;
}

/* .casestudy-library .casestudy-content .casestudy-card:not(:last-child) {
  margin-right: 1rem;
} */

.casestudy-library .casestudy-content .casestudy-card > img,
.casestudy-library .casestudy-content .casestudy-card > video {
  width: 300px;
  height: 400px;
  object-fit: cover;
  object-position: top;
  border-radius: 5px;
  border: 1px solid var(--color-stroke);
}

/* .casestudy-library .casestudy-overlay {
  position: absolute;
  background-color: #fff;
  width: 300px;
  height: auto;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1rem;
  display: flex;
  transition: all 0.3s ease-in-out;
  bottom: 16px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid var(--color-stroke);
  white-space: pre-wrap;
}

.casestudy-library .casestudy-overlay h3 {
  color: var(--color-primary);
  font-size: 1.2rem;
}

.casestudy-library .casestudy-overlay h5 {
  color: var(--color-primary);
  font-size: 1rem;
  font-weight: 400;
}

.casestudy-library .casestudy-overlay p {
  color: var(--color-navbar);
  font-size: 0.8rem;
}

.casestudy-library .casestudy-overlay .casestudy-overlay-button-group {
  display: flex;
  justify-content: flex-end;
  z-index: 9;
}

.casestudy-library .casestudy-overlay .casestudy-overlay-button-group > button,
.casestudy-library .casestudy-overlay .casestudy-overlay-button-group > a {
  cursor: pointer;
  border: none;
  background-color: #fff;
  color: var(--color-secondary);
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  display: inline-block;
  font-size: 1.2em;
}

.casestudy-library
  .casestudy-overlay
  .casestudy-overlay-button-group
  > button:not(:last-child),
.casestudy-library .casestudy-overlay .casestudy-overlay-button-group > a:not(:last-child) {
  margin-right: 0.5rem;
} */

.casestudy-library .casestudy-pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.casestudy-library .empty-casestudy-state {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
}

.casestudy-library .casestudy-library__wrapper {
  border: 5px;
}

.casestudy-library .casestudy-library__wrapper .casestudy-library__header {
  background-color: var(--color-primary);
}

.casestudy-library .casestudy-library__wrapper .casestudy-library__header:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.casestudy-library .casestudy-library__wrapper .casestudy-library__header:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.casestudy-library
  .casestudy-library__wrapper
  .casestudy-library__header
  .ant-collapse-header:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transition: 0.3s all ease;
}

.casestudy-library
  .casestudy-library__wrapper
  .casestudy-library__header
  .ant-collapse-header {
  color: #fff;
}

.casestudy-library .casestudy-library__wrapper .casestudy-library__card {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
  padding: 0.5rem 1rem;
}

@casestudy (min-width: 900px) {
  .casestudy-library .casestudy-library__wrapper .casestudy-library__card {
    grid-template-columns: repeat(2, 1fr);
  }
}

@casestudy (min-width: 1200px) {
  .casestudy-library .casestudy-library__wrapper .casestudy-library__card {
    grid-template-columns: repeat(3, 1fr);
  }
}

@casestudy (min-width: 1400px) {
  .casestudy-library .casestudy-library__wrapper .casestudy-library__card {
    grid-template-columns: repeat(4, 1fr);
  }
}

.casestudy-library__card-item {
  position: relative;
  height: 300px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 30px;
  display: flex;
  z-index: 9999999;
  flex-direction: column;
  border: 1px solid var(--color-stroke);
  white-space: pre-wrap !important;
  overflow: hidden;
  pointer-events: auto !important;
}

.casestudy-library
  .casestudy-library__wrapper
  .casestudy-library__card
  .casestudy-library__card-calendar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.casestudy-library
  .casestudy-library__wrapper
  .casestudy-library__card
  .casestudy-library__card-calendar-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  font-family: Roboto, sans-serif;
}

.casestudy-library
  .casestudy-library__wrapper
  .casestudy-library__card
  .casestudy-library__card-calendar-wrapper {
  display: flex;
  align-items: center;
}

.casestudy-library
  .casestudy-library__wrapper
  .casestudy-library__card
  .casestudy-library__card-calendar-logo {
  margin-right: 12px;
}

.casestudy-library
  .casestudy-library__wrapper
  .casestudy-library__card
  .casestudy-library__card-calendar-edit {
}

.playlist
  .casestudy-library__wrapper
  .casestudy-library__card
  .casestudy-library__card-calendar-edit-logo {
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
}

.casestudy-library
  .casestudy-library__wrapper
  .casestudy-library__card
  .casestudy-library__card-description {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  padding: 10px;
  font-size: 14px;
  line-height: normal;
  z-index: 30;
  width: 100%;
  padding-bottom: 2.2em;
}

.casestudy-library
  .casestudy-library__wrapper
  .casestudy-library__card
  .casestudy-library__card-description
  span {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}

.casestudy-library
  .casestudy-library__wrapper
  .casestudy-library__card
  .casestudy-library__card-description
  p {
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  display: block;
  white-space: pre-wrap;
}

.casestudy-library__card-header {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.casestudy-library__actions {
  z-index: 30;
}
.ant-popover-inner-content {
  padding: 0.5rem 0.5rem !important;
}

.casestudy-library__casestudy-popover {
  display: flex;
  column-gap: 0.25rem;
}

.casestudy-library__casestudy-preview {
  padding: 0.25rem 0.5rem;
  border-bottom-left-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: var(--color-primary);
  color: #fff;
}

.casestudy-library__casestudy-delete {
  padding: 0.25rem 0.5rem;
  outline: none;
  border: none;
  cursor: pointer;
  border-bottom-left-radius: 10px;
  background-color: hsla(0, 62%, 55%, 1);
  color: #fff;
  border-left: 1px solid #fff;
}

.casestudy-library__casestudy-more {
  padding: 0.25rem 0.5rem;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: hsl(212, 46%, 25%);
  color: #fff;
  border-left: 1px solid #fff;
}

.img-overlay {
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 80%;
  bottom: 0px !important;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.56) 100%
  );
}

.casestudy-library__card-img {
  background-position: center;
  background-size: cover;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.casestudy-library__card-video {
  z-index: 10;
}

.casestudy-library__card-info {
  z-index: 30;
  cursor: default;
  text-align: center;
}

.casestudy-library__card-type {
  background-color: hsl(168, 76%, 36%);
  color: #fff;
  padding: 0.25rem 0.5rem;
  display: inline-block;
  border-bottom-right-radius: 10px;
}

.casestudy-library__card-order {
  background-color: var(--color-primary);
  color: #fff;
  padding: 0.25rem 0.5rem;
  display: inline-block;
}

.casestudy-library
  .casestudy-library__header
  .casestudy-library__panel
  .casestudy-library__options {
  display: flex;
  margin-bottom: 12px;
  justify-content: flex-end;
  align-items: center;
  column-gap: 12px;
}

.casestudy-library
  .casestudy-library__header
  .casestudy-library__panel
  .casestudy-library__options
  .casestudy-library__checkbox {
  display: flex;
  column-gap: 12px;
}

.casestudy-library
  .casestudy-library__header
  .casestudy-library__panel
  .casestudy-library__action-buttons {
  display: flex;
  column-gap: 12px;
}

.casestudy-library
  .casestudy-library__header
  .casestudy-library__panel
  .casestudy-library__action-buttons
  .casestudy-library__options
  .casestudy-library__checkbox
  .casestudy-library__checkbox-title {
}

.casestudy-library
  .casestudy-library__header
  .casestudy-library__panel
  .casestudy-library__options
  .casestudy-library__action-buttons
  > .casestudy-library__add-file-btn {
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 10px;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.casestudy-library
  .casestudy-library__header
  .casestudy-library__panel
  .casestudy-library__options
  .casestudy-library__action-buttons
  > .casestudy-library__add-file-btn:hover {
  opacity: 0.8;
}

.casestudy-library
  .casestudy-library__header
  .casestudy-library__panel
  .casestudy-library__options
  .casestudy-library__action-buttons
  > .casestudy-library__set-time-btn {
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 10px;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.casestudy-library
  .casestudy-library__header
  .casestudy-library__panel
  .casestudy-library__options
  .casestudy-library__action-buttons
  > .casestudy-library__set-time-btn:hover {
  opacity: 0.8;
}

.casestudy-library
  .casestudy-library__header
  .casestudy-library__panel
  .casestudy-library__options
  .casestudy-library__action-buttons
  > .casestudy-library__delete-file-btn {
  color: #fff;
  border-radius: 10px;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.casestudy-library
  .casestudy-library__header
  .casestudy-library__panel
  .casestudy-library__options
  .casestudy-library__action-buttons
  > .casestudy-library__delete-file-btn:hover {
  opacity: 0.8;
}

.casestudy-library .casestudy-library__card {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  padding: 0.5rem 1rem;
}

/* .casestudy-library .casestudy-library__card .casestudy-library__card-item {
  position: relative;
  height: 300px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-stroke);
  cursor: default;
  align-items: center;
  justify-content: center;
}

.casestudy-library .casestudy-library__card .casestudy-library__card-item .ant-tag {
  z-index: 5;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.casestudy-library .casestudy-library__card .casestudy-library__card-item ::selection {
  background: none !important;
  color: none !important;
} */

.casestudy-library__card-item:active,
.casestudy-library__card-item:hover {
  cursor: grab;
}

.casestudy-library__card-item:active,
.casestudy-library__card-item:hover {
  cursor: grabbing !important;
}

.casestudy-library__card-item .ant-tag {
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.casestudy-library__card-item ::selection {
  background: none !important;
  color: none !important;
}

.casestudy-library__card-item .img-overlay {
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 80%;
  bottom: 0px !important;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.56) 100%
  );
}

.casestudy-library__card-item .checked-overlay {
  position: absolute;
  z-index: 100;
  inset: 0;
  background: hsla(209, 53%, 28%, 0.58) !important;
}

.casestudy-library__card-item .ant-tag-checkable-checked {
  background: hsla(209, 53%, 28%, 0.58) !important;
}

.casestudy-library__card-item .checked-overlay > .icon-checked {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.casestudy-library__card-calendar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.casestudy-library__card-calendar-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  font-family: Roboto, sans-serif;
}

.casestudy-library__card-calendar-wrapper {
  display: flex;
  align-items: center;
}

.casestudy-library__card-calendar-logo {
  margin-right: 12px;
}

.casestudy-library__card-calendar-edit {
}

.casestudy-library__card-calendar-edit-logo {
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
}

.casestudy-library__card-description {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  padding: 10px;
  font-size: 14px;
  line-height: normal;
  z-index: 30;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) -52.78%,
    rgba(0, 0, 0, 0.56) 100%
  );
}

.casestudy-library__card-description span {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  display: block;
  white-space: pre-wrap;
}

.casestudy-library__card-description p {
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  display: block;
  white-space: pre-wrap;
}

/* .casestudy-library .casestudy-library__card .casestudy-library__card-item .img-overlay {
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 80%;
  bottom: 0px !important;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.56) 100%
  );
}

.casestudy-library .casestudy-library__card .casestudy-library__card-item .checked-overlay {
  position: absolute;
  z-index: 100;
  inset: 0;
  background: hsla(209, 53%, 28%, 0.58) !important;
}

.casestudy-library .casestudy-library__card .ant-tag-checkable-checked {
  background: hsla(209, 53%, 28%, 0.58) !important;
}

.casestudy-library
  .casestudy-library__card
  .casestudy-library__card-item
  .checked-overlay
  > .icon-checked {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.casestudy-overlay-button-group {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
  z-index: 50;
  padding: 10px;
}

.casestudy-overlay-button-group > button,
.casestudy-overlay-button-group > a {
  cursor: pointer;
  border: none;
  background-color: #fff;
  color: var(--color-secondary);
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  display: inline-block;
  font-size: 1.2em;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-1 {
  margin-right: .25rem !important;
}

.casestudy-content-wrapper {
  display: flex;
}

.casestudy-content-wrapper .casestudy-side {
  filter: drop-shadow(0px -4px 12px rgba(0, 0, 0, 0.06));
  width: 250px;
}

.casestudy-content-wrapper .casestudy-side .casestudy-cat-list {
  background-color: #fff;
  padding: 1rem 1.5rem;
  border-bottom: 2px solid #C3CAD9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.casestudy-content-wrapper .casestudy-side .casestudy-cat-list.active, .casestudy-content-wrapper .casestudy-side .casestudy-cat-list:hover {
  background-color: var(--color-primary);
  color: #fff;
}

.casestudy-content-wrapper .casestudy-side .casestudy-cat-list > button.casestudy-cat-list-button {
  background-color: transparent;
  color: var(--color-primary);
  size: 3.5rem;
  border: none;
  box-shadow: none;
  cursor: pointer;
}

.casestudy-content-wrapper .casestudy-side .casestudy-cat-list.active > button.casestudy-cat-list-button,
.casestudy-content-wrapper .casestudy-side .casestudy-cat-list:hover > button.casestudy-cat-list-button {
  color: #fff !important;
}

.casestudy-content-wrapper > .casestudy-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* margin-left: 2rem; */
}

.casestudy-content-wrapper > .casestudy-content .casestudy-tab-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.casestudy-content-wrapper > .casestudy-content .casestudy-tab-wrapper > .casestudy-tab-left,
.casestudy-content-wrapper > .casestudy-content .casestudy-tab-wrapper > .casestudy-tab-right {
  display: flex;
}

.casestudy-content-wrapper > .casestudy-content .casestudy-tab-wrapper > .casestudy-tab-right > p {
  color: var(--color-text-primary);
  font-weight: 600;
  margin: 0px
}

.casestudy-content-wrapper > .casestudy-content .casestudy-tab-wrapper > .casestudy-tab-right > div {
  display: flex;
  align-items: center;
}

.casestudy-content-wrapper > .casestudy-content .casestudy-tab-wrapper > .casestudy-tab-right > div > span {
  font-weight: 600;
  color: var(--color-black);
}

.casestudy-content-wrapper > .casestudy-content .casestudy-tab-wrapper > .casestudy-tab-right > div::before {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 5px;
  margin: 0px 0.5rem;
  background-color: var(--color-stroke-dark);
  display: inline-block;
}

.casestudy-content-wrapper > .casestudy-content .casestudy-tab-wrapper > .casestudy-tab-right div.checkbox {
  margin-left: 0.5rem;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #fff;
  border: 1px solid var(--color-stroke-dark);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .5rem;
  color: #fff
}

.casestudy-content-wrapper > .casestudy-content .casestudy-tab-wrapper > .casestudy-tab-right div.checkbox.checked {
  border: 1px solid var(--primary-button);
  background-color: var(--primary-button);
}

.casestudy-content-wrapper > .casestudy-content .casestudy-tab-wrapper > .casestudy-tab-left > .casestudy-tab {
  margin-right: .25rem;
  padding: .5rem 1.5rem;
  cursor: pointer;
  background-color: var(--color-border);
  color: var(--color-text-light);
  border-top-right-radius: .5rem;
  border-top-left-radius: .5rem;
  font-weight: 600;
  display: flex;
  align-items: flex-end;
  transition: all 250ms ease-in-out;
}

.casestudy-content-wrapper > .casestudy-content .casestudy-tab-wrapper > .casestudy-tab-left > .casestudy-tab.active, .casestudy-content-wrapper > .casestudy-content .casestudy-tab-wrapper > .casestudy-tab-left > .casestudy-tab:hover {
  background-color: var(--primary-button);
  color: #fff;
  font-size: .95rem;
}

.casestudy-content .casestudy-list-content {
  margin-top: 1rem;
  min-height: 300px;
}

.casestudy-content .casestudy-list-content .casestudy-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk {
  display: flex;
  margin-bottom: 1rem;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk>div:not(:last-child) {
  margin-right: 1rem;
}


.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list {
  background-color: #fff;
  flex: 1;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08));
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 0.5rem;
  max-width: 33% !important;
}


.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-container {
  display: flex;
  align-items: flex-start;
  padding: .5rem;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-container .casestudy-list-details h4 {
  font-weight: 700;
  font-size: 1rem;
  color: var(--color-active);
  margin: 0.5rem 0rem;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-container .casestudy-list-details .casestudy-playlist {
  margin-bottom: .5rem;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-container .casestudy-list-details .casestudy-playlist,
.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-container .casestudy-list-details .casestudy-date {
  display: flex;
  align-items: center;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-container .casestudy-list-details .casestudy-playlist > span,
.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-container .casestudy-list-details .casestudy-date > span {
  margin-left: 0.5rem;
  font-weight: 600;
  font-size: .8rem;
  color: var(--action-color);
  font-family: 'Inter', sans-serif;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list.dragged {
  opacity: .2;
  transition: all 250ms ease-in-out;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list.entered {
  border: 2px var(--color-navbar-active-border) dashed;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list-empty {
  background-color: transparent;
  flex: 1;
  display: flex;
  padding: 0;
}


.casestudy-content .casestudy-list-content .casestudy-list-chunk > div:not(:last-child) {
  margin-right: 1rem;
}

/* .casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list:last-child {
  margin-left: .5rem;
} */

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-footer {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-bg-white-blueish);
  padding: .5rem 1rem;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-footer .casestudy-list-footer-left {
  display: flex;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-footer .casestudy-list-footer-left p:first-child {
  margin: 0px;
  color: var(--color-text-light);
  margin-right: 0.5rem;
  font-size: .8rem;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-footer .casestudy-list-footer-left p:last-child {
  margin: 0px;
  color: var(--color-black);  
  font-size: .8rem;
  white-space: nowrap;
  width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-footer .casestudy-list-footer-right {
  display: flex;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-footer .casestudy-list-footer-right > ul {
  display: flex;
  margin: 0px;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-footer .casestudy-list-footer-right > ul > li button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-container .casestudy-list-img,
.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-container .casestudy-list-video {
  width: 120px;
  height: 120px;
  object-fit: contain;
  border: 1px solid var(--color-stroke);
  border-radius: .5rem;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-details {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-details ul.casestudy-list-top {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-details ul.casestudy-list-top > li {
  margin-bottom: .25rem;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-details ul.casestudy-list-top > li > i {
  margin-right: .5rem;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-details ul.casestudy-list-bottom {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-details ul.casestudy-list-bottom li {
  cursor: pointer;
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-details ul.casestudy-list-bottom li:hover {
  color: var(--color-primary);
}

.casestudy-content .casestudy-list-content .casestudy-list-chunk .casestudy-list .casestudy-list-details ul.casestudy-list-bottom > li:not(:last-child) {
  margin-right: 1rem;
}


.playlist-wrapper > .playlist-dropdown {
  position: absolute;
  background-color: #fff;
  top: 2.8rem;
  box-shadow: 0px 6px 24px rgb(0 0 0 / 12%);
  border-radius: 0.25rem;
  width: 200px;
  left: 0;
  cursor: text !important;
}

.playlist-wrapper > .playlist-dropdown.playlist-dropdown-open {
  opacity: 1;
  z-index: 2;
  transition: opacity ease-in-out 250ms;
}

.playlist-wrapper > .playlist-dropdown.playlist-dropdown-closed {
  opacity: 0;
  z-index: -2;
  transition: opacity ease-in-out 250ms, z-index 250ms ease-in-out 150ms;
}

.playlist-wrapper > .playlist-dropdown > ul {
  margin: 0px;
  max-height: 300px;
  overflow-y: auto;
}

.playlist-wrapper > .playlist-dropdown > ul > li.divider {
  border-bottom: 1px solid var(--color-stroke-dark);
  margin: .5rem 1rem;
  padding: 0px;
}

.playlist-wrapper > .playlist-dropdown > ul > li.header {
  padding: 0.5rem 1rem;
  background-color: var(--color-bg-white-blueish);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-active);
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.playlist-wrapper > .playlist-dropdown > ul > li.header > i {
  margin-right: .5rem;
}

.playlist-wrapper > .playlist-dropdown > ul > li {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  color: var(--color-black);
  font-weight: 600;
  font-size: .8rem;
}

.playlist-wrapper > .playlist-dropdown > ul > li.playlist-button {
  background-color: var(--primary-button);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 1rem 1rem 1rem;
  border-radius: 0.5rem;
  color: #fff;
  cursor: pointer;
}

.playlist-wrapper > .playlist-dropdown > ul > li > div.radio,
.playlist-wrapper > .playlist-dropdown > ul > li > div.playlist-filter > .playlist-filter-radio > div.radio {
  width: 15px;
  height: 15px;
  background-color: var(--color-bg-radio);
  margin-right: 0.5rem;
  border-radius: 15px;
  border: 1px solid var(--color-border-radio);
  display: flex;
  align-items: center;
  justify-content: center;
}

.playlist-wrapper > .playlist-dropdown > ul > li > div.radio.active,
.playlist-wrapper > .playlist-dropdown > ul > li > div.playlist-filter > .playlist-filter-radio > div.radio.active {
  border-color: var(--primary-button);
}

.playlist-wrapper > .playlist-dropdown > ul > li > div.radio > div.radio-checked,
.playlist-wrapper > .playlist-dropdown > ul > li > div.playlist-filter > .playlist-filter-radio > div.radio > div.radio-checked {
  width: 8px;
  height: 8px;
  background-color: var(--primary-button);
  border-radius: 8px;
}


.playlist-wrapper > .playlist-dropdown > ul > li > div.playlist-filter {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.playlist-wrapper > .playlist-dropdown > ul > li > div.playlist-filter > .playlist-filter-radio {
  display: flex;
  align-items: center;
}

.playlist-wrapper > .playlist-dropdown > ul > li > div.playlist-filter > button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.casestudy-overlayed {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: var(--color-bg-overlay);
  position: absolute;
  border-radius: 0.25rem;
  display: flex;
  padding: 1rem;
  justify-content: flex-end;
  align-items: flex-start;
}

.casestudy-overlayed > .casestudy-overlayed-check {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: var(--primary-button);
  color: #fff;
  border-radius: 20px;
}

.casestudy-library .btn-group {
  display: flex;
}

.casestudy-library .btn-group > button:not(:last-child) {
  margin-right: .5rem;
}

.casestudy-library .btn-outline-primary:hover,
.casestudy-library .btn-outline-secondary:hover,
.casestudy-library .btn-outline-warning:hover,
.casestudy-library .btn-outline-danger:hover {
  opacity: .5;
}

.casestudy-library .btn-outline-primary {
  display: flex;
  align-items: center;
  border: 1px solid var(--primary-button);
  border-radius: 0.35rem;
  padding: .5rem 1rem;
  color: var(--primary-button);
  font-weight: 600;
  background-color: transparent;
  cursor: pointer;
}

.casestudy-library .btn-outline-secondary  {
  display: flex;
  align-items: center;
  border: 1px solid var(--secondary-button);
  border-radius: 0.35rem;
  padding: .5rem 1rem;
  color: var(--secondary-button);
  font-weight: 600;
  background-color: transparent;
  cursor: pointer;
}

.casestudy-library .btn-outline-warning {
  display: flex;
  align-items: center;
  border: 1px solid var(--warning-button);
  border-radius: 0.35rem;
  padding: .5rem 1rem;
  color: var(--warning-button);
  font-weight: 600;
  background-color: transparent;
  cursor: pointer;
}

.casestudy-library .btn-outline-danger {
  display: flex;
  align-items: center;
  border: 1px solid var(--danger-button);
  border-radius: 0.35rem;
  padding: .5rem 1rem;
  color: var(--danger-button);
  font-weight: 600;
  background-color: transparent;
  cursor: pointer;
}

.casestudy-library .btn-outline-primary > svg,
.casestudy-library .btn-outline-warning > svg,
.casestudy-library .btn-outline-danger > svg {
  margin-right: .5rem;
}

.casestudy-library .casestudy-list-container-content {
  display: flex;
  flex-direction: column;
  padding: 0px 0.5rem;
}

.casestudy-library .casestudy-list-container-content > h1 {
  color: var(--color-active);
  font-weight: 700;
  font-size: 1rem;
}

.casestudy-library .casestudy-list-container-content > p {
  margin: 0px;
  color: var(--action-color);
}

.copied-popover {
  display: flex;
  align-items: center;
  margin: 0px;
}

.copied-popover > i {
  color: #fff;
  padding: .25rem;
  border-radius: 50%;
  background-color: var(--primary-button);
  margin-right: .5rem;
}

.custom-modal .custom-modal-content > h1.custom-modal-title {
  color: var(--color-active);
  font-weight: 600;
  font-size: 1rem;
}

.custom-modal .custom-modal-content > .custom-modal-details > ul {
  margin: 0px;
}

.custom-modal .custom-modal-content > .custom-modal-details > ul > li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: var(--action-color);
}

.custom-modal .custom-modal-content > .custom-modal-details > ul > li > svg {
  width: 18px;
  height: 18px;
  margin-right: 0.5rem;
}

.scroll-container{
  width: 100%;
  height: 120px;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: none; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks */
}


.upload-list {
  margin-top: 10px;
  gap: 10px;
  display: flex;
  flex-direction: row;
}

.upload-list .image-card{
  width: 100px;
  height: 100px;
  padding: 5px;
  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.upload-list .image-card img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.upload-list .delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 88px;
  height: 88px;
  margin: 0.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-list .delete-icon i {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: white;
}