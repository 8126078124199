.slider{
    width: 100%;
    height: 40rem;
    display: flex;
    justify-content: center;
}
.slick-slider{
    width: 40rem;
    height: 40rem;
}

.slick-slider img{height: 40rem;width: 40rem; object-fit: contain;}

.slick-arrow{
    background: grey !important;
}

.ant-modal-content{
    width: 50rem;
}